import { memo, useState, useRef, useEffect } from 'react'
import {
  Typeahead,
  Menu,
  MenuItem,
  Highlighter,
} from 'react-bootstrap-typeahead'

const SearchInput = memo((props) => {
  const [clearButtonStatus, setClearButtonStatus] = useState(
    props.defaultKeyword ? true : false
  )
  const [defaultKeyword, setDefaultKeyword] = useState(props.defaultKeyword)
  const [focus, setFocus] = useState(false)

  const refInput = props?.inputRef || useRef()
  const minLength = props?.minLength || 3

  const inputClear = () => {
    refInput.current.getInstance().clear()
    if (props.returnObject) changeSearchingValue()
    else changeSearchingValue('')
  }

  const onInputChange = (e) => {
    changeSearchingValue(e)
  }

  const changeSearchingValue = (e, isSelect) => {
    props.onValueChange(e, isSelect)
    if ((props.returnObject && e && e[props.labelKey]) || (e && e.length > 0)) {
      setClearButtonStatus(true)
    } else setClearButtonStatus(false)
  }

  const onFocus = () => {
    setFocus(true)
    if (props.onFocus) props.onFocus()
  }

  const onBlur = () => {
    setFocus(false)
    if (props.onBlur) props.onBlur()
  }

  const onKeyDown = (e) => {
    if (props.onKeyDown) props.onKeyDown(e)
  }

  useEffect(() => {
    if (props.defaultKeyword != defaultKeyword)
      setDefaultKeyword(props.defaultKeyword)

    if (props.defaultKeyword && props.defaultKeyword.length > 0)
      setClearButtonStatus(true)
    else setClearButtonStatus(false)
  }, [props.defaultKeyword])

  return (
    <>
      <div
        className={`search-input2 ${props.inputClassName}${
          focus ? ' focus' : ''
        } ${clearButtonStatus ? ' filled' : ''}`}
      >
        {props.icon && <i className={`icon ${props.icon}`}></i>}
        <Typeahead
          inputProps={{
            id: props.id,
            name: props.name,
            autoComplete: 'off',
            // 'data-test': props.dataTest,
            'aria-label': props.placeholder,
          }}
          maxResults={props.pageSize || 10}
          selected={props.defaultKeyword ? [props.defaultKeyword] : []}
          id={props.id}
          labelKey={props.labelKey}
          placeholder={props.placeholder}
          options={props.items}
          minLength={minLength}
          aria-label={props.placeholder}
          emptyLabel={props.emptyLabel ? props.emptyLabel : ' '}
          align="left"
          autoFocus={props?.autoFocus || null}
          paginationText="Daha fazla sonuç göster..."
          onInputChange={onInputChange}
          onChange={(e) =>
            e &&
            e.length &&
            changeSearchingValue(
              props.returnObject ? e[0] : e[0][props.labelKey],
              true
            )
          }
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={refInput}
          filterBy={() => true}
          renderMenu={(results, menuProps) => {
            if (!results.length && !props.emptyLabel) {
              return null
            }
            return (
              <Menu className={props.menuClassName} {...menuProps}>
                {results.map((result, index) => (
                  <MenuItem key={index} option={result} position={index}>
                    <Highlighter search={menuProps.text}>
                      {result[props.labelKey]}
                    </Highlighter>
                  </MenuItem>
                ))}
              </Menu>
            )
          }}
        />

        {!props.disabledClearButton && (
          <span
            onClick={() => inputClear()}
            className={`clear-input ${clearButtonStatus ? 'show' : ''}`}
          >
            ×
          </span>
        )}
      </div>
      <style jsx global>{`
        .search-input2 {
          position: relative;
          display: block;

          .rbt-input-hint {
            display: none !important;
          }

          &.v1 {
            input {
              font-size: 16px;
              height: 60px;
              padding: 0 50px 0 50px;
            }
            .icon {
              top: 18px;
              font-size: 25px;
            }
            .clear-input {
              font-size: 38px;
              line-height: 60px;
              &.show {
                display: block;
              }
            }
            &:hover,
            &:focus {
              .icon {
                color: #1298e6;
              }
            }
          }

          &.v2 {
            border-radius: 5px;
            border: solid 1px #d8d8d8;
            input {
              font-size: 14px;
              height: 34px;
              padding: 0 15px 0 40px;
              width: 100%;
            }
            .icon {
              top: 9px;
              font-size: 17px;
            }
            .clear-input {
              line-height: 18px;
              background-color: #666;
              color: #fff;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              top: calc(50% - 9px);
              right: 14px;
              font-size: 18px;
              font-weight: 500;
              padding: 0;
              text-align: center;
              &:hover {
                color: #fff;
              }
            }
          }

          &.v3 {
            input {
              font-size: 15px;
              height: 46px;
              padding: 0 45px 0 45px;
            }
            .icon {
              top: 15px;
              font-size: 18px;
            }
            .clear-input {
              font-size: 38px;
              line-height: 46px;
            }
          }

          &.v4 {
            input {
              padding-top: 10px;
              height: 40px;
              font-size: 14px;
              padding: 6px 12px;
            }
          }

          &.v5 {
            input {
              font-size: 15px;
              height: 46px;
              padding: 0 45px 0 45px;
              &::placeholder {
                color: #999;
                font-size: 14px;
              }
            }

            .icon {
              color: #666;
              position: absolute;
              left: 15px;
              z-index: 1;
              top: 15px;
              font-size: 18px;
            }

            .clear-input {
              position: absolute;
              right: 0;
              top: 0;
              cursor: pointer;
              background: #fff;
              border-radius: 10px;
              font-size: 38px;
              line-height: 46px;
              padding: 0 15px;
              display: none;
              &:hover {
                color: #e0212b;
              }
              &.show {
                background-color: #666;
                color: #fff;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                top: 14px;
                right: 14px;
                font-size: 18px;
                font-weight: 500;
                padding: 0;
              }
            }
            &:hover,
            &:focus,
            &:focus-within {
              .clear-input.show {
                display: flex;
              }
            }
          }

          &.v6 {
            ///
            .clear-input {
              line-height: 18px;
              background-color: #666;
              color: #fff;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              top: calc(50% - 9px);
              right: 14px;
              font-size: 18px;
              font-weight: 500;
              padding: 0;
              text-align: center;
              &:hover {
                color: #fff;
              }
            }
            &:hover,
            &:focus,
            &:focus-within {
              input {
                padding: 0 42px 0 12px;
              }
              .clear-input.show {
                display: block;
              }
            }
          }

          input {
            border: none;
            box-shadow: none;
            width: 100%;

            &::placeholder {
              color: #999;
              font-size: 14px;
            }
            &:hover,
            &:focus {
              outline: none;
              border: none;
              box-shadow: none;
            }
          }

          .icon {
            color: #666;
            position: absolute;
            left: 15px;
            z-index: 1;
          }

          .clear-input {
            z-index: 2;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            background: #fff;
            border-radius: 10px;
            padding: 0 15px;
            display: none;
            &:hover {
              color: #e0212b;
            }
          }

          .rbt-menu {
            &.v1 {
              width: 100%;
              border: none;
              box-shadow: 0px 2px 70px 0px rgba(18, 16, 11, 0.1);
              border-radius: 3px;
              padding: 0px;
              margin-top: 5px;
              li a {
                font-size: 12px;
                border-top: 1px dashed #ccc;
                padding: 11px 10px;
                color: #555;

                > span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                }

                &:hover,
                &:focus,
                &.active {
                  background: #f0f0f0;
                  color: #555;
                }
                .rbt-highlight-text {
                  border: none;
                  background-color: inherit;
                  color: inherit;
                  font-weight: 600;
                }
              }
              li:first-child a {
                border-color: #fff;
              }
            }

            &.v2 {
              width: 100%;
              min-width: 276px;
              background-color: #fff;
              border-radius: 5px;
              box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.14);
              border: solid 1px #d8d8d8;
              padding: 0;
              overflow: hidden;
              margin-top: 8px;
              max-height: 310px !important;

              li a {
                background-color: #fff;
                font-size: 14px;
                color: #333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: flex;
                align-items: center;
                padding: 8px 16px 8px 16px;

                > span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                }

                &:hover,
                &:focus,
                &.active {
                  text-decoration: none;
                  color: #333;
                  background-color: #f4f4f8;
                }
                .rbt-highlight-text {
                  background-color: inherit;
                  color: inherit;
                  font-weight: 500;
                  padding: 0;
                }
              }
            }

            &.v3 {
              width: 100%;
              border: none;
              box-shadow: 0px 2px 70px 0px rgba(18, 16, 11, 0.1);
              border-radius: 3px;
              padding: 0px;
              margin-top: -2px;
              border-top: 1px solid #e2e2e2;

              li a {
                font-size: 14px;
                padding: 18px;
                border-top: 1px solid #e2e2e2;
                color: #555;

                > span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                }

                &:hover,
                &:focus,
                &.active {
                  background: #f0f0f0;
                  color: #555;
                }
                .rbt-highlight-text {
                  border: none;
                  background-color: inherit;
                  color: inherit;
                  font-weight: 600;
                }
              }
              li:first-child a {
                border-color: #fff;
              }
            }

            &.v4 {
              border: none;
              box-shadow: 0px 2px 70px 0px rgba(18, 16, 11, 0.1);
              border-radius: 3px;
              padding: 0px;
              margin-top: 3px;
              width: 100%;
              max-height: 300px;
              border-top: 1px solid #e2e2e2;

              li a {
                font-size: 13px;
                padding: 10px;
                border-top: 1px solid #e2e2e2;
                color: #555;

                > span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                }

                &:hover,
                &:focus,
                &.active {
                  background: #f0f0f0;
                  color: #555;
                }
                .rbt-highlight-text {
                  border: none;
                  background-color: inherit;
                  color: inherit;
                  font-weight: 600;
                }
              }
              li:first-child a {
                border-color: #fff;
              }
            }

            &.v5 {
              padding: 0px;
              width: 100%;
              margin: 0 !important;
              margin: -4px 0 0 0 !important;
              border-radius: (0 0 5px 5px);
              box-shadow: (
                0 3px 6px 0 rgba(0, 0, 0, 0.06),
                2px 2px 4px 0 rgba(0, 0, 0, 0)
              );
              border: solid 0.7px #c2c2c2;
              max-height: 211px !important;

              li a {
                font-size: 14px;
                padding: 0 14px;
                color: #666;

                > span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;

                  border-top: solid 0.5px #d8d8d8;
                  padding: 9px 0;
                }

                &:hover,
                &:focus,
                &.active {
                  background: #f4f4f8;
                }
                .rbt-highlight-text {
                  border: none;
                  background-color: inherit;
                  color: inherit;
                  font-weight: 600;
                }
              }
              li:first-child a {
                border-color: #fff;
              }

              li.disabled a {
                color: #e0212b;
                padding: 9px 14px;
              }
            }
            &.v6 {
              width: 109%;
              border: none;
              box-shadow: 0px 2px 70px 0px rgba(18, 16, 11, 0.1);
              border-radius: 3px;
              padding: 0px;
              margin-top: -2px;
              position: absolute !important;
              //max-height: 498px !important;
              left: -14px !important;

              &.type1 {
                top: 73px !important;
              }
              &.type2 {
                top: 14px !important;
              }

              li {
                padding: 0 16px 0 16px;

                &:hover,
                &:focus,
                &.active {
                  background: #f4f4f8;
                  color: #333;
                }

                &:last-child a {
                  border-bottom: none;
                }

                &.disabled {
                  margin-top: 15px;
                  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.04),
                    0 1px 3px 0 rgba(0, 0, 0, 0.11);
                }
              }

              li a {
                font-size: 14px;
                padding: 11px 0 12px;
                color: #333;
                border-bottom: 1px solid #d8d8d8;

                > span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                }

                &:hover,
                &:focus,
                &.active {
                  background: #f4f4f8;
                  color: #333;
                }

                .rbt-highlight-text {
                  border: none;
                  background-color: inherit;
                  color: inherit;
                }
              }
              li:last-child {
                border-color: #fff;
              }
            }
          }
          @media (max-width: 992px) {
            &.v1 {
              input {
                height: 46px;
                font-size: 14px;
                padding: 0 45px 0 40px;
                margin-bottom: 12px;
              }
              .icon {
                font-size: 16px;
                top: 15px;
              }

              .clear-input {
                line-height: 46px;
                font-size: 30px;
              }
            }

            .rbt-menu {
              &.v1 {
                max-height: 220px !important;
              }
            }
          }
        }
      `}</style>
    </>
  )
})

export default SearchInput
